import Swal from "sweetalert2";
import Rails from "@rails/ujs";

Rails.confirm = function (message, element) {

  Swal
    .fire({
      title: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'subscription-status-btn',
      confirmButtonText: 'Continue',
    })
    .then((result) => {
      if (result.value) {
        console.log("sweetalert finished");
        element.removeAttribute("data-confirm");
        element.click();
      }
    });
};