/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import jquery from 'jquery';
window.$ = window.jquery = jquery;
require("@rails/ujs").start()
require("jquery-ui")
require("turbolinks").start()
require("@rails/activestorage").start()

require('bootstrap')
require('bootstrap-datepicker')
require('daterangepicker')
require('select2')
require('selectize')
require('trix')
require('intl-tel-input')
require('owl.carousel')

require('chosen-js')

require("chartkick").use(require("highcharts"))

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import intlTelInput from 'intl-tel-input';

document.addEventListener('turbolinks:load', function() {

  if (document.querySelector('#user_mobile')) {
    initTelInput('#user_mobile');
  };

  if (document.querySelector('#new_company_user_mobile')) {
    initTelInput('#new_company_user_mobile');
  };
})

function initTelInput (id) {
  const input = document.querySelector(id);
  window.iti = intlTelInput(input, {
    initialCountry: 'auto',
    geoIpLookup: function(callback) {
      $.get('https://ipinfo.io', (function() {}), 'jsonp').always(function(resp) {
        var countryCode;
        countryCode = resp && resp.country ? resp.country : '';
        callback(countryCode);
      });
    },
    formatOnInit: true,
    separateDialCode: true
  });
}

import ahoy from "ahoy.js";

import Highcharts from 'highcharts';
window.Highcharts = Highcharts;

import Swal from 'sweetalert2';
window.Swal = Swal;

import moment from 'moment';
window.moment = moment;

if (window.jsHelper == null) {
  window.jsHelper = {};
}

require('src/index')
